import React, { useContext, useState } from 'react';
import { graphql, Script } from 'gatsby';

import SearchForm from '../globals/searchForm/searchForm';
import { LangContext } from '../context/LocaleContext';
import ContentBlock from '../components/contentBlock/contentBlock';
import SearchIcon from '../images/search.inline.svg';
import Button from '../components/button/button';
import {search, search__hero, search__four0four, searchEmptyParam} from './scssModules/search.module.scss';
import {four0four} from './scssModules/404.module.scss';


const Search = ({location, data}) => {
  const { getTranslation } = useContext(LangContext);
  const {datoCmsGlobalFourohfourMessage} = data || {};

  const params = new URLSearchParams(location.search);
  let searchParam = params.get('addsearch');
  let hasParam = searchParam && searchParam.length > 0;
  const [ addSearchStatus, setAddSearchStatus ] = useState(false);

  return (
    <> 
      <Script src={`https://addsearch.com/js/?key=${process.env.GATSBY_ADDSEARCH}&type=resultpage`} onLoad={()=>{setAddSearchStatus('ready');}} />
      <Script id={'addsearch'}>
        {
          `
            window.addsearch_settings = {
            display_url: true,
            display_resultscount: true,
            number_of_results: 7,
            display_category: false
          }`
        }
      </Script>
      {
        addSearchStatus === 'ready' && 
        <div className={`${search} ${!hasParam ? searchEmptyParam : '' }`}>
          <div className={search__hero}>
            <div className="container">
              <h1>{getTranslation('SearchResults')}:</h1>
              <span>{searchParam}</span>
              <SearchForm placeholder={`Enter a new search`}>
                <Button type="submit" className="btn--primary--sm" icon={true}>
                  <span className="tablet-min">{getTranslation('SeeAll')}</span>
                  <span className="mobile"><SearchIcon/></span>
                </Button>
              </SearchForm>
            </div>
          </div>
          <div id="addsearch-results"></div>

          { !hasParam ?
            <div className={`${search__four0four} ${four0four} `}>
              <div className='container'>
                <ContentBlock headingTag={`h1`} linkclass={`btn--tertiary`} data={datoCmsGlobalFourohfourMessage?.content[0]} />
              </div>
            </div>
            :
            false
          }
        
        </div>
      }
     
    </>
  )
}

export default Search;


export const query = graphql`
  query{
    datoCmsGlobalFourohfourMessage(location: {eq: "emptySearch"}){
      content{
        ...HeadingCopyLinkFragment
      }
    }
  }`
